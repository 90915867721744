export const aboutMeSkills = [
  'Git',
  'JavaScript',
  'Java',
  'PHP',
  'SQL',
  'Vue.js',
  'React.js',
  'TypeScript',
  'REST',
  'Docker',
  'HTML5',
  'CSS3',
  'Sass',
  'Figma'
];