export const aboutMeTitles = [
  'Software Engineer',
  'Mechanic',
  'Web Developer',
  'Photographer',
  'Backend Engineer',
  'Graphic Designer',
  'Game Developer',
  'Rock Climber',
  'Frontend Engineer',
  'Artist',
];